import { required, SelectInput, useGetOne } from "react-admin";
import * as React from "react";

export const PriceView = ({ scopedFormData, selectedProduct }) => {
  const { data: product, loading } = useGetOne("products", {
    id:
      selectedProduct instanceof Object ? selectedProduct.id : selectedProduct,
  });
  if (!product) return null;
  scopedFormData.price = product.sellingPrice;
  return loading ? null : (
    <div>
      <label>
        <span>Original Price</span>
      </label>
      <div>€ {product.sellingPrice}</div>
    </div>
  );
};

export const ProductOptionInput = ({ scopedFormData, selectedProduct }) => {
  const { data: product, loading } = useGetOne("products", {
    id:
      selectedProduct instanceof Object ? selectedProduct.id : selectedProduct,
  });
  if (!product) return null;
  if (product.options.length === 0) return null;
  const options1 = product.options[0].values.map((option) => {
    return {
      name: option.name,
      skuExtension:
        option.skuExtension === null ? "NOCOR" : option.skuExtension,
    };
  });
  let options2 = null;
  if (product.options.length > 1) {
    options2 = product.options[1].values.map((option) => {
      return {
        name: option.name,
        skuExtension:
          option.skuExtension.length === 0 ? "NOCOR" : option.skuExtension,
      };
    });
  }
  return loading ? null : (
    <>
      {product.options.length > 0 && (
        <SelectInput
          key={"skuProductOption1"}
          record={scopedFormData}
          source="skuProductOption1"
          choices={options1}
          optionText="name"
          optionValue="skuExtension"
          validate={[required()]}
          helperText={false}
        />
      )}
      {product.options.length > 1 && (
        <SelectInput
          key={"skuProductOption2"}
          record={scopedFormData}
          source="skuProductOption2"
          choices={options2}
          optionText="name"
          optionValue="skuExtension"
          validate={[required()]}
          helperText={false}
        />
      )}
    </>
  );
};
