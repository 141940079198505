import {
  Edit,
  NumberInput,
  ReferenceField,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin";
import { CustomNumberInput } from "../CustomNumberInput";
import * as React from "react";

export const ProductCoreEdit = (props) => (
  <Edit title={<ProductCoreTitle />}>
    <SimpleForm>
      <TextInput source="product.sku" readOnly />
      <ReferenceField
        source="product.id"
        reference="products"
        readOnly
      />
      <NumberInput source="amount" readOnly />
      <NumberInput source="amountSentBack" readOnly />
      <CustomNumberInput source="amountCredited" />
      <NumberInput source="price" readOnly />
      <TextInput source="priceCurrency" readOnly />
    </SimpleForm>
  </Edit>
);

const ProductCoreTitle = () => {
  const record = useRecordContext();
  return (
    <span>Cores for product {record ? `"${record.product.name}"` : ""}</span>
  );
};
