import * as React from "react";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import CardWithIcon from "./CardWithIcon";

interface NbNewOrdersProps {
    value?: number
    title?: string
}

const NbNewOrders: React.FC<NbNewOrdersProps> = (props) => {
    return (
        <CardWithIcon
            to="/orders"
            icon={ShoppingCartIcon}
            title={props.title}
            subtitle={props.value}
        />
    );
};

export default NbNewOrders;
