import * as React from "react";
import {
  Edit,
  ReferenceInput,
  SimpleForm,
  Create,
  useRecordContext,
  TextField,
  ReferenceField,
  SelectField,
  Labeled,
  SelectInput,
  required,
  DateInput,
  TextInput,
  AutocompleteInput,
  TopToolbar,
  ListButton,
  Button,
  BooleanInput,
  useDataProvider,
  useRefresh,
  useRedirect,
  BooleanField,
  DateField,
} from "react-admin";
import { Box } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import RetourSupplierButton from "./RetourSupplierButton";

export const ServiceCreate = () => {
  /*const location = useLocation();*/

  return (
    <Create title="Create New Service" component="div" redirect="list">
      <SimpleForm sx={{ maxWidth: 1200 }}>
        <Box minWidth="400px">
          <SelectInput
            source="serviceType"
            choices={[
              {
                id: "CORE_REFUND",
                name: "CORE_REFUND",
              },
              {
                id: "REPAIR",
                name: "REPAIR",
              },
              {
                id: "RETURN",
                name: "RETURN",
              },
            ]}
            validate={[required()]}
          />
          <ReferenceInput
            source="customer.id"
            reference="relations"
            filter={{ customer: true, active: true }}
            sort={{ field: "lastName", order: "ASC" }}
          >
            <AutocompleteInput validate={[required()]} />
          </ReferenceInput>
          <ReferenceInput source="order.id" reference="orders" />
          <ReferenceInput source="product.id" reference="products">
            <AutocompleteInput validate={[required()]} />
          </ReferenceInput>
          <DateInput source="returnDate" />
          <br />
          <BooleanInput source="toCredit" />
          <TextInput source="remark" fullWidth multiline />
        </Box>
      </SimpleForm>
    </Create>
  );
};

const ReturnApprovedButton = ({ serviceId }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { mutate, isPending } = useMutation({
    mutationKey: ["serviceId", serviceId],
    mutationFn: () => dataProvider.returnApprovedService(serviceId),
    onSuccess: (data) => {
      refresh();
    },
  });
  return (
    <Button
      onClick={() => {
        mutate();
      }}
      disabled={isPending}
      size="small"
    >
      Return Approved
    </Button>
  );
};

const CreateCreditNoteButton = ({ serviceId }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { mutate, isPending } = useMutation({
    mutationKey: ["serviceId", serviceId],
    mutationFn: () => dataProvider.createCreditNote(serviceId),
    onSuccess: (data) => {
      refresh();
      redirect("edit", "orders", data.data.id);
    },
  });
  return (
    <Button
      onClick={() => {
        mutate();
      }}
      disabled={isPending}
      size="small"
    >
      Create Credit Note
    </Button>
  );
};

const ServiceEditActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <ListButton />
      {record && record.returnInStockAllowed && (
        <ReturnApprovedButton serviceId={record.id} />
      )}
      {record &&
        record.returnApproved &&
        record.toCredit &&
        !record.credited && <CreateCreditNoteButton serviceId={record.id} />}
    </TopToolbar>
  );
};

const ShowCoreRefundRepairField = () => {
  const record = useRecordContext();
  return (
    <>
      {record &&
        (record.serviceType === "CORE_REFUND" ||
          record.serviceType === "REPAIR") && (
          <>
            <ReferenceInput
              source="retourSupplier.id"
              reference="relations"
              filter={{ supplier: true, active: true }}
              sort={{ field: "company", order: "ASC" }}
            >
              <AutocompleteInput />
            </ReferenceInput>
            <Labeled label="Retour Supplier Tracking Number">
              <TextField source="retourSupplierTrackingNumber" />
            </Labeled>
            <br />
            <Labeled label="Retour Supplier Date">
              <DateField source="retourSupplierDate" />
            </Labeled>
            <br />
            {record.retourSupplierTrackingNumber == null && (
              <RetourSupplierButton />
            )}
          </>
        )}
      {record && record.serviceType === "REPAIR" && (
        <>
          <br />
          <TextInput source="retourCustomerTrackingNumber" />
          <br />
          <DateInput source="retourCustomerDate" />
        </>
      )}
    </>
  );
};

export const ServiceEdit = () => {
  return (
    <Edit
      title={<ServiceTitle />}
      actions={<ServiceEditActions />}
      component="div"
      redirect="list"
    >
      <SimpleForm sx={{ maxWidth: 1200 }}>
        <Box minWidth="400px">
          <Labeled label="Service Type">
            <SelectField
              source="serviceType"
              choices={[
                {
                  id: "CORE_REFUND",
                  name: "CORE_REFUND",
                },
                {
                  id: "REPAIR",
                  name: "REPAIR",
                },
                {
                  id: "RETURN",
                  name: "RETURN",
                },
              ]}
            />
          </Labeled>
          <br />
          <Labeled label="Service Number">
            <TextField source="serviceNumber" />
          </Labeled>
          <br />
          <Labeled label="State">
            <SelectField
              source="state"
              choices={[
                {
                  id: "OPEN",
                  name: "OPEN",
                },
                {
                  id: "PROCESSING",
                  name: "PROCESSING",
                },
                {
                  id: "COMPLETE",
                  name: "COMPLETE",
                },
                {
                  id: "CANCELED",
                  name: "CANCELED",
                },
              ]}
            />
          </Labeled>
          <br />
          <Labeled label="Customer">
            <ReferenceField source="customer.id" reference="relations" />
          </Labeled>
          <br />
          <Labeled label="Order">
            <ReferenceField source="order.id" reference="orders" />
          </Labeled>
          <br />
          <Labeled label="Product">
            <ReferenceField source="product.id" reference="products" />
          </Labeled>
          <br />
          <DateInput source="returnDate" />
          <br />
          <Labeled label="Return Approved">
            <BooleanField source="returnApproved" />
          </Labeled>
          <br />
          <Labeled label="To Credit">
            <BooleanField source="toCredit" />
          </Labeled>
          <br />
          <Labeled label="Credited">
            <BooleanField source="credited" />
          </Labeled>
          <TextInput source="remark" fullWidth multiline />
          <ShowCoreRefundRepairField />
        </Box>
      </SimpleForm>
    </Edit>
  );
};

const ServiceTitle = () => {
  const record = useRecordContext();
  return (
    <span>
      Service{" "}
      {record ? `"${record.serviceNumber} - ${record.customer.fullName}"` : ""}
    </span>
  );
};
