import CardWithIcon from "../CardWithIcon";
import WarningIcon from "@mui/icons-material/Warning";
import {ListBase, SimpleList, WithListContext} from "react-admin";

const ProductsNeedsToBeReordered = () => {
  return (
    <ListBase
      resource="products"
      filter={{
        active: true,
        reorderNeeded: 1,
      }}
      sort={{ field: "createdAt", order: "DESC" }}
      perPage={100}
      disableSyncWithLocation
    >
      <CardWithIcon
        to="/products"
        icon={WarningIcon}
        title="Products Needs To Be Reordered"
        subtitle={<WithListContext render={({ total }) => <>{total}</>} />}
      >
        <SimpleList
          primaryText="%{sku}"
          secondaryText="%{name}"
          tertiaryText="%{availablePhysicalStock}"
        />
      </CardWithIcon>
    </ListBase>
  );
};

export default ProductsNeedsToBeReordered;
