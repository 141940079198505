import {
  DatagridConfigurable,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectColumnsButton,
  TextField,
  TopToolbar,
} from "react-admin";
import * as React from "react";
import TotalOpenCredit from "./TotalOpenCredit";

const productCoreFilters = [
  <ReferenceInput
    source="supplier"
    reference="relations"
    filter={{ supplier: true }}
    sort={{ field: "company", order: "ASC" }}
    alwaysOn
  />,
];

const ProductCoreListActions = () => (
  <TopToolbar>
    <SelectColumnsButton preferenceKey="productCores.datagrid" />
    <TotalOpenCredit />
  </TopToolbar>
);

export const ProductCores = () => (
  <List
    actions={<ProductCoreListActions />}
    perPage={50}
    sort={{ field: "id", order: "ASC" }}
    filters={productCoreFilters}
    filter={{ onlyOpen: true }}
  >
    <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
      <TextField source="product.sku" />
      <ReferenceField source="product.id" reference="products" />
      <ReferenceField
        source="purchaseItem.purchase.id"
        reference="purchases"
        label="Purchase"
      />
      <NumberField source="amount" />
      <NumberField source="amountSentBack" />
      <NumberField source="amountCredited" />
      <NumberField source="price" />
      <TextField source="priceCurrency" />
      {/*<DateField source="createdAt" />
            <DateField source="updatedAt" />*/}
    </DatagridConfigurable>
  </List>
);
