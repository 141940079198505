import * as React from "react";
import { useGetList, useListContext } from "react-admin";
import DollarIcon from "@mui/icons-material/AttachMoney";
import CardWithIcon from "../dashboard/CardWithIcon";

const TotalOpenCredit = () => {
  const { filterValues } = useListContext();
  const { data: cores } = useGetList("productCores", {
    filter: { supplier: filterValues.supplier, onlyOpen: true },
    sort: { field: "createdAt", order: "DESC" },
    pagination: { page: 1, perPage: 1000 },
  });
  if (!cores) return null;

  const aggregations = cores.reduce((accumulator, core) => {
    accumulator += (core.amountSentBack - core.amountCredited) * core.price;
    return accumulator;
  }, 0);

  // todo: hardcoded always USD

  return (
    <CardWithIcon
      /*to="/productCores"*/
      icon={DollarIcon}
      title="Open Credit"
      subtitle={aggregations + " USD"}
    />
  );
};

export default TotalOpenCredit;
