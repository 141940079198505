import * as React from "react";
import { useRecordContext, WrapperField } from "react-admin";

interface PercentNumberFieldProps {
  source: string;
  sx: string;
}

export const PercentNumberField: React.FC<PercentNumberFieldProps> = ({
  source,
  sx,
}) => {
  const record = useRecordContext();
  const percent = record[source];
  return record ? (
    <WrapperField label={source} sortBy={source} sx={sx}>
      <span>{percent} %</span>
    </WrapperField>
  ) : null;
};
