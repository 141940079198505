import { useState } from "react";
import {
  Button,
  useListContext,
  useNotify,
  useRefresh,
  useUnselectAll,
  useDataProvider,
} from "react-admin";
import TextField from "@mui/material/TextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";

const CustomPriceUpdateButton = () => {
  const { selectedIds } = useListContext();
  const [open, setOpen] = useState(false);
  const [percentage, setPercentage] = useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll("products");

  const dataProvider = useDataProvider();
  const { mutate, isPending } = useMutation({
    mutationKey: ["products", "update"],
    mutationFn: () =>
      dataProvider.updateProductsWithPercentage(selectedIds, percentage),
    onSuccess: () => {
      refresh();
      notify("Selected products are updated with " + percentage + "%.");
      unselectAll();
    },
    onError: (error) =>
      notify("Error: products not updated", { type: "warning" }),
  });

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = (e) => {
    e.preventDefault();
    mutate();
    setPercentage("0");
    setOpen(false);
  };

  return (
    <>
      <Button label="Update Price" onClick={handleClick} />
      <Dialog open={open} onClose={handleDialogClose}>
        <DialogTitle>Update Price with percentage</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To increase the price with %, please enter a positive amount here.
            To decrease the price with %, please enter a negative amount here.
          </DialogContentText>
          <form onSubmit={handleConfirm} id="myform">
            <TextField
              value={percentage}
              autoFocus
              label="Percentage"
              type="number"
              onChange={(e) => setPercentage(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleConfirm} type="submit" form="myform">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CustomPriceUpdateButton;
