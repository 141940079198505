import { Card, CardHeader, CardContent } from "@mui/material";
import {
  ResponsiveContainer,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { format, subDays, differenceInDays } from "date-fns";

const dateFormatter = (date) => new Date(date).toLocaleDateString();

const aggregateOrdersByDay = (orders) =>
  orders
    .filter((order) => order.state !== "CANCELED" && order.state !== "OPEN")
    .reduce((acc, curr) => {
      const day = format(new Date(curr.createdAt), "yyyy-MM-dd");
      if (!acc[day]) {
        acc[day] = 0;
      }
      acc[day] += curr.subtotal;
      return acc;
    }, {});

const getRevenuePerDay = (orders, startDate, endDate) => {
  const daysWithRevenue = aggregateOrdersByDay(orders);
  const days = differenceInDays(endDate, startDate);
  const lastMonthDays = Array.from({ length: days + 1 }, (_, i) =>
    subDays(endDate, i),
  );
  return lastMonthDays.map((date) => ({
    date: date.getTime(),
    total: daysWithRevenue[format(date, "yyyy-MM-dd")] || 0,
  }));
};

const OrderChart = ({ orders, startDate, endDate }) => {
  if (!orders) return null;

  return (
    <Card>
      <CardHeader title="Revenue History" />
      <CardContent>
        <div style={{ width: "100%", height: 300 }}>
          <ResponsiveContainer>
            <AreaChart data={getRevenuePerDay(orders, startDate, endDate)}>
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="date"
                name="Date"
                type="number"
                scale="time"
                domain={[startDate.getTime(), endDate.getTime()]}
                tickFormatter={dateFormatter}
              />
              <YAxis dataKey="total" name="Revenue" unit="€" />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                cursor={{ strokeDasharray: "3 3" }}
                formatter={(value) =>
                  new Intl.NumberFormat(undefined, {
                    style: "currency",
                    currency: "EUR",
                  }).format(value)
                }
                labelFormatter={(label) => dateFormatter(label)}
              />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
                strokeWidth={2}
                fill="url(#colorUv)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </CardContent>
    </Card>
  );
};

/*interface TotalByDay {
    date: number;
    total: number;
}*/

export default OrderChart;
