import * as React from "react";
import {
  Create,
  ReferenceInput,
  SimpleForm,
  AutocompleteInput,
  FormDataConsumer,
  ArrayInput,
  SimpleFormIterator,
  required,
  CreateButton,
  TextInput,
  BooleanInput,
} from "react-admin";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { Spacer } from "../Spacer";
import { PriceView, ProductOptionInput } from "./QuoteGeneral";
import AddressInput from "./AddressInput";
import { CustomNumberInput } from "../CustomNumberInput";

const QuoteItems = (props, source) => {
  return (
    <ArrayInput source="items">
      <SimpleFormIterator inline disableReordering>
        <Grid container columnSpacing={2}>
          <Grid item xs={10} sm={4}>
            <ReferenceInput
              label="Product"
              reference="products"
              source="product"
              filter={{ active: true }}
            >
              <AutocompleteInput validate={[required()]} />
            </ReferenceInput>
          </Grid>
          <FormDataConsumer>
            {({
              formData, // The whole form data
              scopedFormData, // The data for this item of the ArrayInput
              ...rest
            }) =>
              scopedFormData && scopedFormData.product ? (
                <>
                  <Grid item xs={10} sm={1}>
                    <PriceView
                      scopedFormData={scopedFormData}
                      selectedProduct={scopedFormData.product}
                    />
                  </Grid>
                  <Grid item xs={10} sm={1}>
                    <ProductOptionInput
                      scopedFormData={scopedFormData}
                      selectedProduct={scopedFormData.product}
                    />
                  </Grid>
                </>
              ) : null
            }
          </FormDataConsumer>
          <Grid item xs={10} sm={2}>
            <CustomNumberInput source="qty" validate={[required()]} />
          </Grid>
          <Grid item xs={10} sm={2}>
            <CustomNumberInput source="discountPercent" />
          </Grid>
        </Grid>
      </SimpleFormIterator>
    </ArrayInput>
  );
};

export const QuoteCreate = () => {
  return (
    <Create title="Create a Quote" redirect="list">
      <SimpleForm>
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h6" gutterBottom>
              Customer
            </Typography>
            <ReferenceInput
              label="Customer"
              source="customer"
              reference="relations"
              filter={{ customer: true, active: true }}
            >
              <AutocompleteInput validate={[required()]} fullWidth />
            </ReferenceInput>
            <Grid>
              <CreateButton resource="relations" label="Create Customer" />
            </Grid>
            <Spacer />
            <Typography variant="h8">Shipping Address</Typography>
            <AddressInput
              source="shippingAddress.customerAddressId"
              otherAddressAllowed
              type="shipping"
            />
            <Spacer />
            <Typography variant="h8">Billing Address</Typography>
            <AddressInput
              source="billingAddress.customerAddressId"
              type="billing"
            />
            <Spacer />
            <TextInput source="referenceNumber" />
            <BooleanInput source="projectOrder" />
          </Grid>
        </Grid>
        <Spacer />
        <Typography variant="h6" gutterBottom>
          Items
        </Typography>
        <QuoteItems />
        <Spacer />
        <Typography variant="h6" gutterBottom>
          Totals
        </Typography>
        <Box>
          <Grid container spacing={1}>
            <Grid item>
              <Typography>Discount amount</Typography>
              <CustomNumberInput
                source="discountAmount"
                options={{ style: "currency", currency: "EUR" }}
              />
              <Typography>Payment Fee</Typography>
              <CustomNumberInput
                source="paymentFeeAmount"
                options={{ style: "currency", currency: "EUR" }}
              />
              <Typography>Shipping</Typography>
              <CustomNumberInput
                source="shippingAmount"
                options={{ style: "currency", currency: "EUR" }}
              />
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Create>
  );
};
