import * as React from "react";
import { NumberField, useRecordContext } from "react-admin";

export const CurrencyNumberField = ({ source, currency, sx }) => {
  const record = useRecordContext();
  const options = record[currency]
    ? { style: "currency", currency: record[currency] }
    : {};
  return record ? (
    <NumberField source={source} options={options} sx={sx} />
  ) : null;
};
