import {
  Create,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  required,
  useNotify,
  useRedirect,
  TextInput,
  Toolbar,
  SaveButton,
} from "react-admin";
import { useLocation } from "react-router";
import { CustomNumberInput } from "../CustomNumberInput";

const today = new Date();
const InventoryAdjustmentCreate = (props) => {
  // Read the post_id from the location
  const location = useLocation();
  const product_id =
    location.state && location.state.record
      ? location.state.record.product_id
      : undefined;

  const redirectPath =
    location.state && location.state.record
      ? location.state.record.redirectPath
      : undefined;

  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify(`Stock Adjustment saved`);
    if (redirectPath) {
      redirect(redirectPath);
    } else {
      const r = product_id ? `/products/${product_id}/3` : false;
      redirect(r);
    }
  };

  const InventoryAdjustmentCreateToolbar = () => (
    <Toolbar>
      <SaveButton alwaysEnable />
    </Toolbar>
  );

  return (
    <Create {...props} mutationOptions={{ onSuccess }}>
      <SimpleForm toolbar={<InventoryAdjustmentCreateToolbar />}>
        <ReferenceInput source="product_id" reference="products">
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
        <ReferenceInput
          source="reason_id"
          reference="inventoryAdjustmentReasons"
        >
          <SelectInput optionText="name" validate={required()} />
        </ReferenceInput>
        <CustomNumberInput source="amount" validate={required()} />
        <TextInput source="remark" />
      </SimpleForm>
    </Create>
  );
};

export default InventoryAdjustmentCreate;
