import * as React from "react";
import { Box, Button, Grid, Typography, useMediaQuery } from "@mui/material";
import {
  List,
  SimpleList,
  Datagrid,
  SearchInput,
  TextField,
  BooleanField,
  EmailField,
  ChipField,
  SelectField,
  FunctionField,
  DateField,
  ArrayField,
  UrlField,
  Create,
  Edit,
  TextInput,
  ArrayInput,
  BooleanInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  SimpleFormIterator,
  Show,
  TabbedShowLayout,
  Tab,
  TabbedForm,
  FormTab,
  required,
  useRecordContext,
  FormDataConsumer,
  ReferenceManyField,
  Pagination,
  NumberField,
  TopToolbar,
  ListButton,
  useDataProvider,
  useRefresh,
} from "react-admin";
import { CurrencyNumberField } from "./CurrencyNumberField";
import { useMutation } from "@tanstack/react-query";

const relationFilters = [
  <SearchInput source="q" alwaysOn />,
  <BooleanInput source="customer" defaultValue="true" />,
  <BooleanInput source="supplier" defaultValue="true" />,
  <BooleanInput source="active" alwaysOn />,
];

const CreateDealerAccountButton = ({ relationId, enabled }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const { mutate, isPending } = useMutation({
    mutationKey: ["relationId", relationId],
    mutationFn: () => dataProvider.createDealerAccount(relationId),
    onSuccess: (data) => {
      refresh();
    },
  });
  return (
    <Button
      label="Create Dealer Account"
      onClick={() => {
        mutate();
      }}
      disabled={isPending || !enabled}
      size="small"
    >
      Create Dealer Account
    </Button>
  );
};

const RelationEditActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <ListButton />
      {record && (
        <CreateDealerAccountButton
          relationId={record.id}
          enabled={record.dealer && !record.magentoCustomerIdentifier}
        />
      )}
    </TopToolbar>
  );
};

export const RelationList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      filters={relationFilters}
      perPage={25}
      sort={{ field: "lastName", order: "ASC" }}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) =>
            `${record.company ? record.company : record.lastName + ", " + record.firstName}`
          }
          secondaryText={(record) =>
            `${record.company && (record.lastName || record.firstName) ? record.lastName + ", " + record.firstName : ""}`
          }
        />
      ) : (
        <Datagrid rowClick="edit">
          <TextField source="company" />
          <FunctionField
            label="Name"
            sortBy="lastName"
            render={(record) =>
              `${record.lastName || record.firstName ? record.lastName + ", " + record.firstName : ""}`
            }
          />
          <TextField source="telephone" />
          <TextField source="mobile" />
          <EmailField source="email" />
          <TextField source="vatNumber" label="VAT number" />
          <BooleanField source="customer" label="Customer?" />
          <BooleanField source="supplier" label="Supplier?" />
        </Datagrid>
      )}
    </List>
  );
};

export const RelationShow = (props) => (
  <Show {...props} title={<RelationTitle />}>
    <TabbedShowLayout>
      <Tab label="Relation Info" sx={{ maxWidth: 500 }}>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextField source="firstName" fullWidth helperText={false} />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextField source="lastName" fullWidth helperText={false} />
          </Box>
        </Box>
        <TextField source="company" fullWidth helperText={false} />
        <UrlField source="website" fullWidth helperText={false} />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextField source="telephone" fullWidth helperText={false} />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextField source="mobile" fullWidth helperText={false} />
          </Box>
        </Box>
        <EmailField
          source="email"
          validate={[required()]}
          fullWidth
          helperText={false}
        />
        <TextField
          source="vatNumber"
          label="VAT number"
          fullWidth
          helperText={false}
        />
        <ChipField source="customerGroup" />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <BooleanField
              source="supplier"
              label="Supplier?"
              helperText={false}
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <BooleanField
              source="customer"
              label="Customer?"
              helperText={false}
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <BooleanField source="guest" label="Guest?" />
          </Box>
        </Box>
        <BooleanField source="active" helperText={false} />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </Tab>
      <Tab label="Addresses">
        <ArrayField source="addresses" sort={{ field: "id", order: "ASC" }}>
          <Datagrid rowClick={false}>
            <SelectField
              source="type"
              choices={[
                { id: "shipping", name: "shipping" },
                { id: "billing", name: "billing" },
              ]}
              helperText={false}
            />
            <TextField source="firstName" helperText={false} />
            <TextField source="lastName" helperText={false} />
            <TextField source="company" helperText={false} />
            <TextField source="telephone" helperText={false} />
            <TextField source="email" helperText={false} />
            <TextField
              source="street1"
              validate={[required()]}
              helperText={false}
            />
            <TextField source="street2" helperText={false} />
            <TextField source="street3" helperText={false} />
            <TextField
              source="postcode"
              validate={[required()]}
              helperText={false}
            />
            <TextField
              source="city"
              validate={[required()]}
              helperText={false}
            />
            <TextField source="region" helperText={false} />
            <TextField
              source="country"
              validate={[required()]}
              helperText={false}
            />
            <BooleanField source="defaultBilling" helperText={false} />
            <BooleanField source="defaultShipping" helperText={false} />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
);

/*const toChoices = items => items.map(item => ({ id: item, name: item }));
const RegionInput = props => {
    const country = useWatch({ name: 'country' });
    console.log(country);
    return (
        <SelectInput
            choices={country ? country.regions : []}
            {...props}
        />
    );
};*/

export const RelationEdit = (props) => (
  <Edit {...props} title={<RelationTitle />} actions={<RelationEditActions />}>
    <TabbedForm>
      <FormTab label="Relation Info" sx={{ maxWidth: 500 }}>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="firstName" fullWidth helperText={false} />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="lastName" fullWidth helperText={false} />
          </Box>
        </Box>
        <TextInput source="company" fullWidth helperText={false} />
        <TextInput source="website" fullWidth helperText={false} />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="telephone" fullWidth helperText={false} />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="mobile" fullWidth helperText={false} />
          </Box>
        </Box>
        <TextInput
          source="email"
          validate={[required()]}
          fullWidth
          helperText={false}
        />
        <TextInput
          source="vatNumber"
          label="VAT number"
          fullWidth
          helperText={false}
        />
        <TextInput
            source="invoiceEmail"
            label="Invoice Email"
            fullWidth
            helperText={false}
        />
        <SelectInput
          source="customerGroup"
          choices={[
            { id: "PARTICULIER", name: "PARTICULIER" },
            { id: "BUSINESS_WITH_VAT", name: "BUSINESS_WITH_VAT" },
            { id: "BUSINESS_WITHOUT_VAT", name: "BUSINESS_WITHOUT_VAT" },
            { id: "MEDIUM_DEALERS_WITH_VAT", name: "MEDIUM_DEALERS_WITH_VAT" },
            {
              id: "MEDIUM_DEALERS_WITHOUT_VAT",
              name: "MEDIUM_DEALERS_WITHOUT_VAT",
            },
          ]}
          helperText={false}
        />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <BooleanInput
              source="supplier"
              label="Supplier?"
              helperText={false}
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <BooleanInput
              source="customer"
              label="Customer?"
              helperText={false}
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <BooleanField source="guest" label="Guest?" />
          </Box>
        </Box>
        <BooleanInput source="active" helperText={false} />
        <DateField source="createdAt" showTime />
        <DateField source="updatedAt" showTime />
      </FormTab>
      <FormTab label="Addresses">
        <ArrayInput source="addresses">
          <SimpleFormIterator disableReordering fullWidth={false}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                <SelectInput
                  source="type"
                  choices={[
                    { id: "shipping", name: "shipping" },
                    { id: "billing", name: "billing" },
                  ]}
                  helperText={false}
                />
              </Grid>
              <Grid item xs={0} sm={8}></Grid>
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  ...rest
                }) =>
                  scopedFormData ? (
                    <>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="firstName"
                          defaultValue={formData.firstName}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="lastName"
                          defaultValue={formData.lastName}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextInput
                          source="company"
                          defaultValue={formData.company}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="telephone"
                          defaultValue={formData.telephone}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="email"
                          defaultValue={formData.email}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={0} sm={4}></Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="street1"
                          defaultValue={formData.addresses[0].street1}
                          validate={[required()]}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="street2"
                          defaultValue={formData.addresses[0].street2}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="street3"
                          defaultValue={formData.addresses[0].street3}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="postcode"
                          defaultValue={formData.addresses[0].postcode}
                          validate={[required()]}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="city"
                          defaultValue={formData.addresses[0].city}
                          validate={[required()]}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="region"
                          defaultValue={formData.addresses[0].region}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <ReferenceInput
                          label="Country"
                          source="country"
                          reference="countries"
                          defaultValue={formData.addresses[0].country}
                          helperText={false}
                        >
                          <AutocompleteInput validate={[required()]} />
                        </ReferenceInput>
                      </Grid>
                    </>
                  ) : null
                }
              </FormDataConsumer>
              <Grid item xs={12} sm={4}>
                <BooleanInput source="defaultBilling" helperText={false} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <BooleanInput source="defaultShipping" helperText={false} />
              </Grid>
            </Grid>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="Orders">
        <Typography variant="h6">Orders</Typography>
        <ReferenceManyField
          reference="orders"
          target="customer"
          sort={{ field: "createdAt", order: "DESC" }}
          perPage={10}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <DateField label="Created at" source="createdAt" />
            <TextField source="orderNumber" />
            <ChipField label="State" source="state" />
            <NumberField source="totalQty" />
            <CurrencyNumberField
              source="grandTotal"
              currency="currencyCode"
              sx={{ fontWeight: "bold" }}
            />
            <BooleanField source="shipped" />
            <BooleanField source="invoiced" />
            <TextField source="remark" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
      <FormTab label="Purchases">
        <Typography variant="h6">Purchases</Typography>
        <ReferenceManyField
          reference="purchases"
          target="supplier"
          sort={{ field: "orderDate", order: "DESC" }}
          perPage={10}
          pagination={<Pagination />}
        >
          <Datagrid rowClick="edit" bulkActionButtons={false}>
            <DateField source="orderDate" />
            <TextField source="orderNumber" />
            <ChipField source="state" />
            <BooleanField source="invoiced" />
            <BooleanField source="received" />
            <TextField source="remark" />
          </Datagrid>
        </ReferenceManyField>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const relationDefaultValue = () => ({ active: true });

export const RelationCreate = (props) => (
  <Create {...props} title="Create New Relation">
    <TabbedForm defaultValues={relationDefaultValue}>
      <FormTab label="Relation Info" sx={{ maxWidth: 500 }}>
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="firstName" fullWidth helperText={false} />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="lastName" fullWidth helperText={false} />
          </Box>
        </Box>
        <TextInput source="company" fullWidth helperText={false} />
        <TextInput source="website" fullWidth helperText={false} />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="telephone" fullWidth helperText={false} />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <TextInput source="mobile" fullWidth helperText={false} />
          </Box>
        </Box>
        <TextInput
          source="email"
          validate={[required()]}
          fullWidth
          helperText={false}
        />
        <TextInput
          source="vatNumber"
          label="VAT number"
          fullWidth
          helperText={false}
        />
        <SelectInput
          source="customerGroup"
          choices={[
            { id: "PARTICULIER", name: "PARTICULIER" },
            { id: "BUSINESS_WITH_VAT", name: "BUSINESS_WITH_VAT" },
            { id: "BUSINESS_WITHOUT_VAT", name: "BUSINESS_WITHOUT_VAT" },
            { id: "MEDIUM_DEALERS_WITH_VAT", name: "MEDIUM_DEALERS_WITH_VAT" },
            {
              id: "MEDIUM_DEALERS_WITHOUT_VAT",
              name: "MEDIUM_DEALERS_WITHOUT_VAT",
            },
          ]}
          helperText={false}
        />
        <Box display={{ xs: "block", sm: "flex", width: "100%" }}>
          <Box flex={1} mr={{ xs: 0, sm: "0.5em" }}>
            <BooleanInput
              source="supplier"
              label="Supplier?"
              helperText={false}
            />
          </Box>
          <Box flex={1} ml={{ xs: 0, sm: "0.5em" }}>
            <BooleanInput
              source="customer"
              label="Customer?"
              helperText={false}
            />
          </Box>
        </Box>
        <BooleanInput source="active" helperText={false} />
      </FormTab>
      <FormTab label="Addresses">
        <ArrayInput source="addresses">
          <SimpleFormIterator disableReordering fullWidth={false}>
            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={4}>
                <SelectInput
                  source="type"
                  choices={[
                    { id: "shipping", name: "shipping" },
                    { id: "billing", name: "billing" },
                  ]}
                  helperText={false}
                />
              </Grid>
              <Grid item xs={0} sm={8}></Grid>
              <FormDataConsumer>
                {({
                  formData, // The whole form data
                  scopedFormData, // The data for this item of the ArrayInput
                  ...rest
                }) =>
                  scopedFormData ? (
                    <>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="firstName"
                          defaultValue={formData.firstName}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="lastName"
                          defaultValue={formData.lastName}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextInput
                          source="company"
                          defaultValue={formData.company}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="telephone"
                          defaultValue={formData.telephone}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="email"
                          defaultValue={formData.email}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={0} sm={4}></Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="street1"
                          defaultValue={formData.addresses[0].street1}
                          validate={[required()]}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="street2"
                          defaultValue={formData.addresses[0].street2}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="street3"
                          defaultValue={formData.addresses[0].street3}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="postcode"
                          defaultValue={formData.addresses[0].postcode}
                          validate={[required()]}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="city"
                          defaultValue={formData.addresses[0].city}
                          validate={[required()]}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <TextInput
                          source="region"
                          defaultValue={formData.addresses[0].region}
                          helperText={false}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <ReferenceInput
                          label="Country"
                          source="country"
                          reference="countries"
                          defaultValue={formData.addresses[0].country}
                          helperText={false}
                        >
                          <AutocompleteInput validate={[required()]} />
                        </ReferenceInput>
                      </Grid>
                    </>
                  ) : null
                }
              </FormDataConsumer>
              <Grid item xs={12} sm={4}>
                <BooleanInput source="defaultBilling" helperText={false} />
              </Grid>
              <Grid item xs={12} sm={4}>
                <BooleanInput source="defaultShipping" helperText={false} />
              </Grid>
            </Grid>
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
);

const RelationTitle = () => {
  const record = useRecordContext();
  return (
    <span>
      Relation{" "}
      {record
        ? record.company
          ? `"${record.company}"`
          : `"${record.lastName} , ${record.firstName}"`
        : ""}
    </span>
  );
};
