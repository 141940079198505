import * as React from "react";
import { useMediaQuery } from "@mui/material";
import {
  ReferenceInput,
  SearchInput,
  List,
  SimpleList,
  TextField,
  DatagridConfigurable,
  ChipField,
  ReferenceField,
  DateField,
  SelectInput,
  BooleanField,
} from "react-admin";
import CustomDownloadButton from "../CustomDownloadButton";

const PostBulkActionButtons = () => (
  <>
      <CustomDownloadButton url={`/services/createDHLShipmentFile?type=CUSTOMER&`} bulk={true} bulkResource="services"
                            label="Generate DHL file to customer" successfulMessage="For selected services are DHL Shipment file generated." />
      <CustomDownloadButton url={`/services/createDHLShipmentFile?type=SUPPLIER&`} bulk={true} bulkResource="services"
                          label="Generate DHL file to supplier" successfulMessage="For selected services are DHL Shipment file generated." />
  </>
);

const serviceFilters = [
  <SearchInput source="q" alwaysOn />,
  <ReferenceInput
    source="customer"
    reference="relations"
    filter={{ customer: true, active: true }}
    sort={{ field: "lastName", order: "ASC" }}
  />,
  <SelectInput alwaysOn
    source="state"
    choices={[
      {
        id: "OPEN",
        name: "OPEN",
      },
      {
        id: "PROCESSING",
        name: "PROCESSING",
      },
      {
        id: "COMPLETE",
        name: "COMPLETE",
      },
      {
        id: "CANCELED",
        name: "CANCELED",
      },
    ]}
  />,
  <SelectInput alwaysOn
    source="serviceType"
    choices={[
      {
        id: "CORE_REFUND",
        name: "CORE_REFUND",
      },
      {
        id: "REPAIR",
        name: "REPAIR",
      },
      {
        id: "RETURN",
        name: "RETURN",
      },
    ]}
  />,
];

const ServiceList = (props) => {
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  return (
    <List
      {...props}
      filters={serviceFilters}
      sort={{ field: "serviceNumber", order: "DESC" }}
      perPage={25}
      filterDefaultValues = {{state : "PROCESSING"}}
    >
      {isSmall ? (
        <SimpleList
          primaryText={(record) => record.serviceType}
          secondaryText={(record) => record.serviceNumber}
          tertiaryText={(record) => record.state}
        />
      ) : (
        <DatagridConfigurable
          rowClick="edit"
          bulkActionButtons={<PostBulkActionButtons />}
        >
          <TextField source="serviceNumber" />
          <ChipField source="serviceType" />
          <ReferenceField
            label="Customer"
            reference="relations"
            source="customer.id"
            link={false}
          />
          <ReferenceField
            label="Order"
            reference="orders"
            source="order.id"
            link={false}
          />
          <ReferenceField
            label="Product"
            reference="products"
            source="product.id"
            link={false}
          />
          <DateField source="returnDate" />
          <ReferenceField
            label="Retour Supplier"
            reference="relations"
            source="retourSupplier.id"
            link={false}
          />
          <DateField source="retourSupplierDate" />
          <ChipField source="state" />
          <BooleanField source="returnApproved" />
          <BooleanField source="toCredit" />
          <BooleanField source="credited" />
          <TextField source="remark" />
        </DatagridConfigurable>
      )}
    </List>
  );
};

export default ServiceList;
