import { Box, Button, Card, CardContent, Input } from "@mui/material";
import { useDataProvider, Title, Form } from "react-admin";
import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { format, startOfMonth } from "date-fns";
import CustomDownloadButton from "./CustomDownloadButton";
import * as React from "react";

const SyncProductsButton = ({ status }) => {
  const dataProvider = useDataProvider();
  const { mutate, isPending } = useMutation({
    mutationKey: ["status", status],
    mutationFn: () => dataProvider.syncMagentoProducts(status),
  });
  return (
    <Button label="SyncProducts" onClick={() => mutate()} disabled={isPending}>
      Sync now
    </Button>
  );
};

const AdminConfiguration = () => {
  const [syncDate, setSyncDate] = useState("");
  const [intrastatDate, setIntrastatDate] = useState(startOfMonth(new Date()));
  const dataProvider = useDataProvider();
  const { mutate, isPending } = useMutation({
    mutationKey: ["syncDate", syncDate],
    mutationFn: () => {
      dataProvider.syncMagentoOrdersFromDate(syncDate);
    },
  });

  function syncOrders() {
    mutate();
  }

  return (
    <Card>
      <Title title="Trigger synchronizations" />
      <CardContent>
        <Box sx={{ width: "10em", display: "inline-block" }}>
          {"Sync Magento Products"}
        </Box>
        <SyncProductsButton status="1" />
      </CardContent>
      <CardContent>
        <Box sx={{ width: "10em", display: "inline-block" }}>
          {"Sync Magento Orders"}
        </Box>
        <Form onSubmit={syncOrders}>
          <input
            type="datetime-local"
            value={syncDate}
            onChange={(e) => setSyncDate(e.target.value)}
          />
          <Button type="submit" disabled={isPending}>
            Sync now
          </Button>
        </Form>
      </CardContent>
      <CardContent>
        <Box sx={{ width: "10em", display: "inline-block" }}>
          {"Generate dealers product list"}
        </Box>
        <CustomDownloadButton url="/products/generateProductList"
                              label="Generate ProductList" successfulMessage="Download File" />
        <CustomDownloadButton url="/products/generateProductListCSV"
                              label="Generate ProductList CSV" successfulMessage="Download File" />
      </CardContent>
      <CardContent>
        <Box sx={{ width: "10em", display: "inline-block" }}>
          {"Generate Intrastat"}
        </Box>
        <Input
          type="month"
          value={format(intrastatDate, "yyyy-MM")}
          onChange={(e) => setIntrastatDate(new Date(e.target.value))}
        />
        <CustomDownloadButton url={`/orders/generateIntrastatCSV/` + format(intrastatDate, "yyyy-MM-dd")}
                              label="Generate Intrastat CSV" successfulMessage="Download File" />
      </CardContent>
    </Card>
  );
};

export default AdminConfiguration;
