import React, { useEffect } from "react";
import {
  Admin,
  Resource,
  CustomRoutes,
  addRefreshAuthToDataProvider,
} from "react-admin";
import myDataProvider from "./myDataProvider";
import authConfig, {
  msalConfig,
  tokenRequest,
  tokenStockApiRequest,
} from "./authConfig";
import {
  LoginPage,
  msalAuthProvider,
  msalRefreshAuth,
} from "ra-auth-msal";
import { PublicClientApplication } from "@azure/msal-browser";
import { BrandList, BrandEdit, BrandCreate } from "./components/Brands";
import { OrderList } from "./components/order/Orders";
import Dashboard from "./components/dashboard/Dashboard";
import NotFound from "./components/NotFound";
import MyLayout from "./components/MyLayout";
import {
  RelationCreate,
  RelationEdit,
  RelationList,
} from "./components/Relations";
import { OrderEdit } from "./components/order/OrderEdit";
import { ModelList, ModelEdit, ModelCreate } from "./components/Models";
import {
  ProductTypeList,
  ProductTypeEdit,
  ProductTypeCreate,
} from "./components/ProductTypes";
import AdminConfiguration from "./components/AdminConfiguration";
import { Route } from "react-router";
import { QuoteCreate } from "./components/quote/QuoteCreate";
import { QuoteEdit } from "./components/quote/QuoteEdit";
import { PurchaseList } from "./components/purchase/Purchases";
import {
  PurchaseCreate,
  PurchaseEdit,
} from "./components/purchase/PurchaseEdit";
import { OrderCommentCreate } from "./components/order/OrderCommentCreate";
import { QuoteList } from "./components/quote/Quotes";
import { QuoteShow } from "./components/quote/QuoteShow";
import ProductList from "./components/product/Products";
import ProductEdit from "./components/product/ProductEdit";
import ProductCreate from "./components/product/ProductCreate";
import Reports from "./components/reports/Reports";
import InventoryAdjustmentCreate from "./components/product/InventoryAdjustmentCreate";
import { PurchaseShow } from "./components/purchase/PurchaseShow";
import ReportStock from "./components/reports/ReportStock";
import InventoryValue from "./components/reports/InventoryValue";
import CurrencyRate from "./components/reports/CurrencyRate";
import RevenueReport from "./components/reports/RevenueReport";
import ProductDashboard from "./components/dashboard/product/ProductDashboard";
import ServiceList from "./components/service/Services";
import { ServiceCreate, ServiceEdit } from "./components/service/ServiceEdit";
import StockCount from "./components/stockCount/StockCount";
import ProductCoreCreate from "./components/product/ProducCoreCreate";
import { ProductCores } from "./components/productCore/ProductCores";
import { ProductCoreEdit } from "./components/productCore/ProductCoreEdit";
import {customMsalHttpClient} from "./customMsalHttpClient";

const myMSALObj = new PublicClientApplication(msalConfig);

export const App: React.FC = () => {
  const [isMSALInitialized, setMSALInitialized] = React.useState(false);
  useEffect(() => {
    myMSALObj.initialize().then(() => {
      setMSALInitialized(true);
    });
  }, []);

  const authProvider = msalAuthProvider({
    msalInstance: myMSALObj,
    tokenRequest,
  });

  const httpClient = customMsalHttpClient({
    msalInstance: myMSALObj,
    tokenRequest: tokenStockApiRequest,
  });

  const dataProvider = addRefreshAuthToDataProvider(
    myDataProvider(`${authConfig.serverHost}/api`, httpClient),
    msalRefreshAuth({
      msalInstance: myMSALObj,
      tokenRequest,
    }),
  );

  if (!isMSALInitialized) {
    return <div>Loading...</div>;
  }

  return (
      <Admin
        title="PureTurbos - Admin"
        dashboard={Dashboard}
        dataProvider={dataProvider}
        authProvider={authProvider}
        catchAll={NotFound}
        layout={MyLayout}
        loginPage={LoginPage}
      >
        <Resource
          name="purchases"
          list={PurchaseList}
          edit={PurchaseEdit}
          create={PurchaseCreate}
          show={PurchaseShow}
          recordRepresentation={(record) => `${record.orderNumber}`}
        />
        <Resource
          name="products"
          list={ProductList}
          edit={ProductEdit}
          create={ProductCreate}
          recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
          name="productCores"
          create={ProductCoreCreate}
          list={ProductCores}
          edit={ProductCoreEdit}
        />
        <Resource
          name="relations"
          list={RelationList}
          edit={RelationEdit}
          create={RelationCreate}
          recordRepresentation={(record) =>
            `${record.company ? record.company : record.lastName + ", " + record.firstName}`
          }
        />
        <Resource
          name="inventoryAdjustments"
          create={InventoryAdjustmentCreate}
        />
        <Resource
          name="inventoryAdjustmentReasons"
          recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
          name="brands"
          list={BrandList}
          edit={BrandEdit}
          create={BrandCreate}
          recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
          name="models"
          list={ModelList}
          edit={ModelEdit}
          create={ModelCreate}
          recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
          name="productTypes"
          list={ProductTypeList}
          edit={ProductTypeEdit}
          create={ProductTypeCreate}
          recordRepresentation={(record) => `${record.name}`}
        />
        <Resource
          name="orders"
          list={OrderList}
          edit={OrderEdit}
          recordRepresentation={(record) => `${record.orderNumber}`}
        />
        <Resource
          name="quotes"
          list={QuoteList}
          show={QuoteShow}
          create={QuoteCreate}
          edit={QuoteEdit}
          recordRepresentation={(record) => `${record.orderNumber}`}
        />
        <Resource name="carriers" />
        <Resource name="currencyRates" />
        <Resource name="orderComments" create={OrderCommentCreate} />
        <Resource
          name="countries"
          recordRepresentation={(record) => `${record.fullName}`}
        />
        <Resource name="inventorySummaries" list={ReportStock} />
        <Resource name="inventoryValues" list={InventoryValue} />
        <Resource name="marginPerBrandPerMonths" list={RevenueReport} />
        <Resource name="orderItems" />
        <Resource
          name="purchaseItems"
          recordRepresentation={(record) =>
            `${record.purchase.orderNumber + " - " + record.product.name}`
          }
        />
        <Resource
          name="services"
          list={ServiceList}
          edit={ServiceEdit}
          create={ServiceCreate}
        />
        <CustomRoutes>
          <Route path="/adminConfiguration" element={<AdminConfiguration />} />
          <Route path="/currencyRate" element={<CurrencyRate />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/productDashboard" element={<ProductDashboard />} />
          <Route path="/stockCount" element={<StockCount />} />
        </CustomRoutes>
      </Admin>
  );
};
