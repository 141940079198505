import { stringify } from "query-string";

export default (apiUrl, httpClient) => ({
  getList: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      pageNo: JSON.stringify(page - 1),
      pageSize: JSON.stringify(perPage),
      filter: JSON.stringify(params.filter),
    };

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url)
      .then(({ headers, json }) => ({
        data: json.result,
        total: json.totalItems,
      }))
      .catch((error) => console.log(error));
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`)
      .then(({ json }) => ({
        data: json,
      }))
      .catch((error) => console.log(error)),

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;
    return httpClient(url)
      .then(({ json }) => ({ data: json.result }))
      .catch((error) => console.log(error));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      pageNo: JSON.stringify(page - 1),
      pageSize: JSON.stringify(perPage),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url)
      .then(({ headers, json }) => ({
        data: json.result,
        total: json.totalItems,
      }))
      .catch((error) => console.log(error));
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error)),

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  create: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      body: JSON.stringify(params.data),
    })
      .then(({ json }) => ({
        data: { ...params.data, id: json.id },
      }))
      .catch((error) => console.log(error)),

  delete: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error)),

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  syncMagentoProducts: (status) => {
    const query = {
      status: status,
    };
    return httpClient(
      `${apiUrl}/products/syncMagentoProducts?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  syncMagentoOrdersFromDate: (syncDate) => {
    const query = {
      syncDate: JSON.stringify(syncDate),
    };
    return httpClient(
      `${apiUrl}/orders/syncMagentoOrdersFromDate?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  createYukiInvoices: (orderIds) => {
    const query = {
      ids: orderIds,
    };
    return httpClient(
      `${apiUrl}/orders/createInvoicesInYuki?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  calculateMargins: (orderIds) => {
    const query = {
      ids: orderIds,
    };
    return httpClient(`${apiUrl}/orders/calculateMargins?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  updateProductsWithPercentage: (selectedIds, percentage) => {
    const query = {
      filter: JSON.stringify({ id: selectedIds }),
      percentage: percentage,
    };
    return httpClient(
      `${apiUrl}/products/updateProductsWithPercentage?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  syncMagentoStock: (productId) => {
    const query = {
      id: productId,
    };
    return httpClient(
      `${apiUrl}/products/updateMagentoStock?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  getCarriers: () => {
    const url = `${apiUrl}/carriers`;

    return httpClient(url)
      .then(({ json }) => ({
        data: json.result,
      }))
      .catch((error) => console.log(error));
  },

  getPaymentMethods: () => {
    const url = `${apiUrl}/paymentMethods`;

    return httpClient(url)
      .then(({ json }) => ({
        data: json.result,
      }))
      .catch((error) => console.log(error));
  },

  createShipment: (orderId, items, carrier, trackingNumber, dropShipment) => {
    const query = {
      id: orderId,
      items: JSON.stringify(items),
      carrier: carrier,
      trackingNumber: trackingNumber,
      dropShipment: dropShipment,
    };
    return httpClient(`${apiUrl}/orders/createShipment?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  createDPDShipment: (orderId, items) => {
    const query = {
      id: orderId,
      items: JSON.stringify(items),
    };
    return httpClient(
      `${apiUrl}/orders/createDPDShipment?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  setPaid: (orderId) => {
    const query = {
      id: orderId,
    };
    return httpClient(`${apiUrl}/orders/paid?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  syncMagentoOrders: (orderIds) => {
    const query = {
      ids: orderIds,
    };
    return httpClient(
      `${apiUrl}/orders/syncMagentoOrders?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  cancelOrder: (orderId) => {
    const query = {
      id: orderId,
    };
    return httpClient(`${apiUrl}/orders/cancel?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  completeProjectOrder: (orderId) => {
    const query = {
      id: orderId,
    };
    return httpClient(
      `${apiUrl}/orders/completeProjectOrder?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  revertToQuote: (orderId) => {
    const query = {
      id: orderId,
    };
    return httpClient(`${apiUrl}/orders/revertToQuote?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  getTopCustomers: (startDate, endDate) => {
    const query = {
      startDate: startDate,
      endDate: endDate,
      top: 10,
    };

    return httpClient(`${apiUrl}/reports/topCustomers?${stringify(query)}`)
      .then(({ json }) => ({
        data: json.result,
      }))
      .catch((error) => console.log(error));
  },

  approveQuote: (orderId) => {
    const query = {
      id: orderId,
    };
    return httpClient(`${apiUrl}/quotes/approve?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  cloneQuote: (order) => {
    return httpClient(`${apiUrl}/quotes/clone`, {
      method: "POST",
      body: JSON.stringify(order),
    })
      .then(({ json }) => ({
        data: { ...json, id: json.id },
      }))
      .catch((error) => console.log(error));
  },

  invoicePurchase: (purchaseId) => {
    const query = {
      id: purchaseId,
    };
    return httpClient(`${apiUrl}/purchases/invoiced?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  createReceipt: (purchaseId, items, carrier, trackingNumber, dropShipment) => {
    const query = {
      id: purchaseId,
      items: JSON.stringify(items),
      carrier: carrier,
      trackingNumber: trackingNumber,
      dropShipment: dropShipment,
    };
    return httpClient(`${apiUrl}/purchases/createReceipt?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  createDealerAccount: (relationId) => {
    const query = {
      id: relationId,
    };
    return httpClient(
      `${apiUrl}/relations/createDealerAccount?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  startStockCounting: () => {
    return httpClient(`${apiUrl}/inventoryAdjustments/startStockCounting`, {
      method: "POST",
    })
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  stockCountingOk: (productId) => {
    const query = {
      id: productId,
    };
    return httpClient(
      `${apiUrl}/products/stockCountingOk?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  createCreditNote: (serviceId) => {
    const query = {
      serviceId: serviceId,
    };
    return httpClient(`${apiUrl}/quotes/createCreditNote?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({
        data: { ...json, id: json.id },
      }))
      .catch((error) => console.log(error));
  },

  returnApprovedService: (serviceId) => {
    const query = {
      serviceId: serviceId,
    };
    return httpClient(`${apiUrl}/services/returnApproved?${stringify(query)}`, {
      method: "POST",
    })
      .then(({ json }) => ({
        data: { ...json, id: json.id },
      }))
      .catch((error) => console.log(error));
  },

  updateServiceWithRetourSupplier: (
    serviceId,
    retourSupplierTrackingNumber,
    retourSupplierDate,
  ) => {
    const query = {
      serviceId: serviceId,
      retourSupplierTrackingNumber: retourSupplierTrackingNumber,
      retourSupplierDate: retourSupplierDate,
    };
    return httpClient(
      `${apiUrl}/services/updateServiceWithRetourSupplier?${stringify(query)}`,
      {
        method: "POST",
      },
    )
      .then(({ json }) => ({ data: json }))
      .catch((error) => console.log(error));
  },

  downloadFile: (
      url,
  ) => {
    return httpClient(
        `${apiUrl}${url}`
    )
        .then(({ headers, blob }) => {
          const contentDisposition = headers.get("Content-Disposition");

          const filename = contentDisposition
              .split(";")[1]
              .split("filename")[1]
              .split("=")[1]
              .trim()
              .replaceAll('"', "");

          const objUrl = URL.createObjectURL(blob);
          return { filename, objUrl }
        })
        .catch((error) => console.log(error));
  },
});
