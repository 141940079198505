import React, { useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Input,
  InputLabel,
  useMediaQuery,
} from "@mui/material";
import { useGetList } from "react-admin";
import { format, lastDayOfMonth, parseISO, startOfMonth } from "date-fns";
import MonthlyRevenue from "./MonthlyRevenue";
import NbNewOrders from "./NbNewOrders";
import OrderChart from "./OrderChart";
import TopDashboard from "./TopDashboard";
import BrandChart from "./BrandChart";
import { Spacer, VerticalSpacer } from "../Spacer";

const styles = {
  flex: { display: "flex" },
  flexColumn: { display: "flex", flexDirection: "column" },
  leftCol: { flex: 1, marginRight: "0.5em" },
  rightCol: { flex: 1, marginLeft: "0.5em" },
  singleCol: { marginTop: "1em", marginBottom: "1em" },
};

const Dashboard = () => {
  const isXSmall = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isSmall = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(lastDayOfMonth(new Date()));

  const { data: orders } = useGetList("orders", {
    filter: {
      createdAt_gte: startDate
        ? format(parseISO(format(startDate, "yyyy-MM-dd")), "yyyy-MM-dd")
        : null,
      createdAt_lte: endDate
        ? format(parseISO(format(endDate, "yyyy-MM-dd")), "yyyy-MM-dd")
        : null,
    },
    sort: { field: "createdAt", order: "DESC" },
    pagination: { page: 1, perPage: 1000 },
  });

  const aggregation = useMemo(() => {
    if (!orders) return {};
    const aggregations = orders
      .filter((order) => order.state !== "CANCELED" && order.state !== "OPEN")
      .reduce(
        (stats, order) => {
          if (order.state !== "CANCELED" && order.state !== "OPEN") {
            stats.revenue += order.subtotal;
            if (order.dealerOrder) {
              stats.nbNewDealerOrders++;
            } else {
              stats.nbNewOrders++;
            }
          }
          return stats;
        },
        {
          revenue: 0,
          nbNewOrders: 0,
          nbNewDealerOrders: 0,
        },
      );
    return {
      recentOrders: orders,
      revenue: aggregations.revenue.toLocaleString(undefined, {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      }),
      nbNewOrders: aggregations.nbNewOrders,
      nbNewDealerOrders: aggregations.nbNewDealerOrders,
    };
  }, [orders]);

  const { nbNewOrders, nbNewDealerOrders, revenue, recentOrders } = aggregation;

  return isXSmall ? (
    <div>
      <div style={styles.flexColumn}>
        <TopDashboard />
        <MonthlyRevenue value={revenue} />
        <VerticalSpacer />
        <NbNewOrders value={nbNewOrders} title="New Orders" />
        <Spacer />
        <NbNewOrders value={nbNewDealerOrders} title="New dealer orders" />
      </div>
    </div>
  ) : isSmall ? (
    <div style={styles.flexColumn}>
      <div style={styles.singleCol}>
        <TopDashboard />
      </div>
      <div style={styles.flex}>
        <MonthlyRevenue value={revenue} />
        <Spacer />
        <NbNewOrders value={nbNewOrders} title="New orders" />
        <Spacer />
        <NbNewOrders value={nbNewDealerOrders} title="New dealer orders" />
      </div>
      <div style={styles.singleCol}>
        <OrderChart orders={recentOrders} />
      </div>
      <div style={styles.singleCol}>
        <BrandChart orders={recentOrders} />
      </div>
    </div>
  ) : (
    <div>
      <TopDashboard />
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div>
            <Card sx={{ padding: "20px", marginTop: 2, marginBottom: "1em" }}>
              <CardContent>
                <div style={styles.flex}>
                  <div style={{ marginRight: "0.5em" }}>
                    <InputLabel shrink>Start Date</InputLabel>
                    <Input
                      type="date"
                      value={format(startDate, "yyyy-MM-dd")}
                      onChange={(e) => setStartDate(new Date(e.target.value))}
                    />
                  </div>
                  <div style={{ marginRight: "0.5em" }}>
                    <InputLabel shrink>End Date</InputLabel>
                    <Input
                      type="date"
                      value={format(endDate, "yyyy-MM-dd")}
                      onChange={(e) => setEndDate(new Date(e.target.value))}
                    />
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div style={styles.flex}>
            <MonthlyRevenue value={revenue} />
            <Spacer />
            <NbNewOrders value={nbNewOrders} title="New orders" />
            <Spacer />
            <NbNewOrders value={nbNewDealerOrders} title="New dealer orders" />
          </div>
          <div style={styles.singleCol}>
            <OrderChart
              orders={recentOrders}
              startDate={startDate}
              endDate={endDate}
            />
          </div>
          <div style={styles.singleCol}>
            <BrandChart orders={recentOrders} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
