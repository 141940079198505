import * as React from "react";

import {
  List,
  TextField,
  NumberField,
  useRecordContext,
  DateInput,
  BooleanInput,
  SearchInput,
  DatagridConfigurable,
  TopToolbar,
  SelectColumnsButton,
  FilterButton,
  ExportButton,
} from "react-admin";

const productFilters = [
  <SearchInput source="q" alwaysOn />,
  <DateInput source="startDate" alwaysOn />,
  <DateInput source="endDate" alwaysOn />,
  <BooleanInput label="Only reorder needed?" source="reorder" alwaysOn />,
];

const FormattedNumberField = ({ source, props }) => {
  const record = useRecordContext();
  return (
    <NumberField
      sx={{ color: record && record[source] < 0 ? "red" : "" }}
      source={source}
    />
  );
};

const ReportStockListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <ExportButton />
  </TopToolbar>
);

const ReportStock = (props) => {
  const postRowClick = (id, resource, record) => "/products/" + id;

  return (
    <List
      {...props}
      filters={productFilters}
      hasCreate={false}
      sort={{ field: "physicalStock", order: "ASC" }}
      perPage={50}
      resource="inventorySummaries"
      actions={<ReportStockListActions />}
    >
      <DatagridConfigurable rowClick={postRowClick} bulkActionButtons={false}>
        <TextField source="sku" />
        <TextField source="skuSupplier" sortable={false} />
        <TextField source="name" sortable={false} />
        <NumberField source="reorderPoint" sortable={false} />
        <NumberField source="quantityOrdered" sortable={false} />
        <NumberField source="quantityIn" sortable={false} />
        <NumberField source="quantityOut" />
        <NumberField source="accountingStock" sortable={false} />
        <NumberField source="commitedAccountingStock" sortable={false} />
        <FormattedNumberField
          source="availableAccountingStock"
          sortable={false}
        />
        <NumberField source="physicalStock" sortable={false} />
        <NumberField source="commitedPhysicalStock" sortable={false} />
        <FormattedNumberField source="availablePhysicalStock" />
      </DatagridConfigurable>
    </List>
  );
};

export default ReportStock;
