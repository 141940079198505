import Html5QrcodePlugin from "./Html5QrcodePlugin";
import { Title, useDataProvider, Button } from "react-admin";
import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  TextField,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import ProductPanel from "./ProductPanel";
import { Html5QrcodeSupportedFormats } from "html5-qrcode";
import { useSearchParams } from "react-router-dom";
import StartIcon from "@mui/icons-material/Start";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SearchIcon from "@mui/icons-material/Search";

const StartStockCountingButton = () => {
  const dataProvider = useDataProvider();
  const { mutate, isPending } = useMutation({
    mutationKey: [],
    mutationFn: () => dataProvider.startStockCounting(),
  });
  return (
    <Button
      label="Start Stock Counting"
      variant="contained"
      onClick={() => mutate()}
      disabled={isPending}
    >
      <StartIcon />
    </Button>
  );
};

const StockCount = () => {
  const [scanResult, setScanResult] = useState();
  const [skuField, setSkuField] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("productSku")) {
      setScanResult(searchParams.get("productSku"));
    }
  }, []);

  const onNewScanResult = (decodedText, decodedResult) => {
    setScanResult(decodedText);
  };

  const handleCancel = () => setScanResult(null);

  const handleSkuSearch = () => setScanResult(skuField);

  return (
    <Grid>
      <Title title="Stock Counting" />
      <Card>
        <CardHeader
          avatar={<Avatar sx={{ backgroundColor: "#2196f3" }}>1</Avatar>}
          title="Stock Counting"
        />
        <CardContent>
          <Typography>
            Start the stock counting by setting all the products which need to
            be counted.
          </Typography>
        </CardContent>
        <CardActions>
          <StartStockCountingButton />
        </CardActions>
      </Card>
      <Card>
        <CardHeader
          avatar={<Avatar sx={{ backgroundColor: "#2196f3" }}>2</Avatar>}
          title="Stock Counting"
        />
        <CardContent>
          {!scanResult && (
            <div>
              <Html5QrcodePlugin
                fps={30}
                qrbox={250}
                disableFlip={false}
                qrCodeSuccessCallback={onNewScanResult}
                formatsToSupport={[Html5QrcodeSupportedFormats.QR_CODE]}
              />
              <br />
              <Grid
                container
                direction="row"
                spacing={2}
                columns={2}
                alignItems="center"
                padding={2}
              >
                <TextField
                  value={scanResult}
                  label="SKU product"
                  type="text"
                  onChange={(e) => setSkuField(e.target.value)}
                />
                <Button
                  onClick={handleSkuSearch}
                  variant="contained"
                  label="Search"
                >
                  <SearchIcon />
                </Button>
              </Grid>
            </div>
          )}
          {scanResult && (
            <div>
              <ProductPanel product={scanResult} />
              <br />
              <Button onClick={handleCancel} variant="outlined" label="Next">
                <NavigateNextIcon />
              </Button>
            </div>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default StockCount;
