import * as React from "react";
import DollarIcon from "@mui/icons-material/AttachMoney";

import CardWithIcon from "./CardWithIcon";

interface MonthlyRevenueProps {
    value?: string
}

const MonthlyRevenue:React.FC<MonthlyRevenueProps> = (props) => {
    return (
        <CardWithIcon
            to="/orders"
            icon={DollarIcon}
            title="Revenue"
            subtitle={props.value}
        />
    );
};

export default MonthlyRevenue;
