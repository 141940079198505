import * as React from "react";
import { Box, Card, CardActions, Button, Typography } from "@mui/material";

const TopDashboard = () => {
  return (
    <Card
      sx={{
        padding: "20px",
        marginTop: 2,
        marginBottom: "1em",
      }}
    >
      <Box display="flex">
        <Box flex="1">
          <Typography variant="h5" component="h2" gutterBottom>
            Welcome to PureTurbos.eu - Orders/Products/Stock app
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default TopDashboard;
