import { useState } from "react";
import {
  Button,
  useNotify,
  useRefresh,
  useDataProvider,
  useRecordContext,
} from "react-admin";
import TextField from "@mui/material/TextField";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { format, parseISO } from "date-fns";
import { useMutation } from "@tanstack/react-query";
import * as React from "react";

const RetourSupplierButton = () => {
  const record = useRecordContext();
  const [open, setOpen] = useState(false);
  const [retourSupplierTrackingNumber, setRetourSupplierTrackingNumber] =
    useState(null);
  const [retourSupplierDate, setRetourSupplierDate] = useState(new Date());
  const refresh = useRefresh();
  const notify = useNotify();

  const dataProvider = useDataProvider();
  const { mutate, isPending } = useMutation({
    mutationKey: ["service", "update"],
    mutationFn: () =>
      dataProvider.updateServiceWithRetourSupplier(
        record.id,
        retourSupplierTrackingNumber,
        retourSupplierDate ? format(retourSupplierDate, "yyyy-MM-dd") : null,
      ),
    onSuccess: () => {
      refresh();
      notify("Service is updated.");
    },
    onError: (error) =>
      notify("Error: service not updated", { type: "warning" }),
  });

  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);

  const handleConfirm = (e) => {
    e.preventDefault();
    mutate();
    setRetourSupplierTrackingNumber(null);
    setRetourSupplierDate(new Date());
    setOpen(false);
  };

  return (
    <>
      <Button label="Return Supplier" onClick={handleClick} />
      <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="sm">
        <DialogTitle>Return Part to Supplier</DialogTitle>
        <DialogContent>
          <form onSubmit={handleConfirm} id="myform">
            <TextField
              value={retourSupplierTrackingNumber}
              autoFocus
              label="Retour Supplier Tracking Number"
              type="text"
              onChange={(e) => setRetourSupplierTrackingNumber(e.target.value)}
              fullWidth
            />
            <br />
            <TextField
              value={format(retourSupplierDate, "yyyy-MM-dd")}
              label="Retour Supplier Date"
              type="date"
              onChange={(e) => setRetourSupplierDate(new Date(e.target.value))}
              fullWidth
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleConfirm} type="submit" form="myform">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RetourSupplierButton;
