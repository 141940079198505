import * as React from "react";
import { Layout, CheckForApplicationUpdate } from "react-admin";
import { MyMenu } from "./MyMenu";
//import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const MyLayout = ({ children }: { children: React.ReactNode }) => (
  <Layout menu={MyMenu}>
    {children}
    <CheckForApplicationUpdate />
    {/*<<ReactQueryDevtools initialIsOpen={false} />*/}
  </Layout>
);

export default MyLayout;
