import { NumberField, useRecordContext } from "react-admin";
import * as React from "react";

const MargeNumberField = ({ source, dealerField }) => {
  const record = useRecordContext();
  return (
    <NumberField
      sx={{
        color:
          record && record[source] < (dealerField ? 10 : 20)
            ? "red"
            : record && record[source] < (dealerField ? 15 : 30)
              ? "orange"
              : "",
      }}
      source={source}
      options={{ maximumFractionDigits: 2 }}
    />
  );
};

export default MargeNumberField;
