import { NumberField, useRecordContext } from "react-admin";
import { Tooltip } from "@mui/material";
import * as React from "react";

interface MargeCurrencyFieldProps {
  source: string;
  total: number;
  currency: string;
  dealerOrder: string;
}

const MargeCurrencyField: React.FC<MargeCurrencyFieldProps> = ({
  source,
  total,
  currency,
  dealerOrder,
}) => {
  const record = useRecordContext();
  const marge = record && ((record[source] * 100) / record[total]).toFixed(0);
  return (
    /*<Tooltip title={marge + " %"}>*/
      <NumberField
        sx={{
          color:
            marge < (record && record[dealerOrder] ? 10 : 20)
              ? "red"
              : marge < (record && record[dealerOrder] ? 15 : 30)
                ? "orange"
                : "",
        }}
        source={source}
        options={{ style: "currency", currency: record[currency] }}
      />
    /*</Tooltip>*/
  );
};

export default MargeCurrencyField;
