import { useRecordContext } from "react-admin";
import { Chip } from "@mui/material";
import * as React from "react";

export const TextArrayField = ({ source }) => {
  const record = useRecordContext();
  const array = record[source];
  if (typeof array === "undefined" || array === null || array.length === 0) {
    return <div />;
  } else {
    return (
      <>
        {array.map((item) => (
          <Chip label={item} key={item} />
        ))}
      </>
    );
  }
};
