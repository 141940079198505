import * as React from "react";
import {Button, useDataProvider, useListContext, useNotify, useUnselectAll} from "react-admin";
import {useState} from "react";
import {stringify} from "query-string";

interface CustomDownloadButtonProps {
    url: string;
    bulk?: boolean;
    label: string;
    successfulMessage: string;
    bulkResource?: string;
}

const CustomDownloadButton: React.FC<CustomDownloadButtonProps> = ({ url, bulk= false, label, successfulMessage, bulkResource }) => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();
    const { selectedIds } = (!bulk ? {} : useListContext());
    const unselectAll = useUnselectAll(bulkResource);

    async function downloadFile() {
        setLoading(true);

        if (bulk) {
            const query = {
                ids: selectedIds,
            };
            if (url.endsWith("&")) {
                url += `${stringify(query)}`;
            } else {
                url += `?${stringify(query)}`;
            }

        }
        const {filename, objUrl} = await dataProvider.downloadFile(url);

        const link = document.createElement("a");
        link.href = objUrl;
        link.download = filename;
        link.click();

        setTimeout(() => {
            URL.revokeObjectURL(objUrl);
        }, 250);

        notify(successfulMessage);
        setLoading(false);
        if (bulk) {
            unselectAll();
        }
    }

    return (
        <Button label={label} onClick={downloadFile} disabled={loading} size="small"/>
    );
};

export default CustomDownloadButton;