import { Options } from "react-admin";
import { PublicClientApplication, SilentRequest } from "@azure/msal-browser";
import HttpError from "ra-core/src/dataProvider/HttpError";
import {createHeadersFromOptions} from "ra-core/src/dataProvider/fetch";

const defaultTokenRequest = {
    scopes: ["User.Read"],
    forceRefresh: false,
};

export type CustomMsalHttpClientParams = {
    msalInstance: PublicClientApplication;
    tokenRequest?: SilentRequest;
};

export const customMsalHttpClient = ({
                                   msalInstance,
                                   tokenRequest = defaultTokenRequest,
                               }: CustomMsalHttpClientParams) => async (url: string, options: Options = {}) => {
    const account = msalInstance.getActiveAccount();
    const authResult = await msalInstance.acquireTokenSilent({
        account,
        ...tokenRequest,
    });
    const token = authResult?.accessToken;
    const user = {
        authenticated: !!token,
        token: `Bearer ${token}`,
    };
    return customFetchJson(url, { ...options, user });
};

const customFetchJson = (url, options: Options = {}) => {
    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, { ...options, headers: requestHeaders })
        .then((response) => {
            const contentType = response.headers.get('content-type');

            if (contentType && contentType.includes('application/json')) {
                return response.text().then((text) => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: text,
                    blob: null,
                }));
            } else {
                return response.blob().then((blob) => ({
                    status: response.status,
                    statusText: response.statusText,
                    headers: response.headers,
                    body: null,
                    blob: blob,
                }));
            }
        })
        .then(({ status, statusText, headers, body, blob}) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            if (status < 200 || status >= 300) {
                return Promise.reject(
                    new HttpError(
                        (json && json.message) || statusText,
                        status,
                        json
                    )
                );
            }
            return Promise.resolve({ status, headers, body, json, blob });
        });
};