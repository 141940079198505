import {
  DateField,
  Show,
  Labeled,
  ListButton,
  NumberField,
  ReferenceField,
  TextField,
  TopToolbar,
  useRecordContext,
  ArrayField,
  Datagrid,
  SingleFieldList,
  ChipField,
  SimpleForm,
  EditButton,
  useDataProvider,
  useRefresh,
  BooleanField,
  useRedirect,
  PrevNextButtons,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { Spacer } from "../Spacer";
import * as React from "react";
import { BillingAddress, ShippingAddress } from "../order/OrderEdit";
import { PercentNumberField } from "../PercentNumberField";
import { TextArrayField } from "../TextArrayField";
import { useMutation } from "@tanstack/react-query";
import CustomProductField from "../CustomProductField";
import CustomDownloadButton from "../CustomDownloadButton";

const QuoteTitle = () => {
  const record = useRecordContext();
  return record ? <span>Quote {record.orderNumber}</span> : null;
};

const ApproveButton = ({ orderId }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { mutate, isPending } = useMutation({
    mutationKey: ["orderId", orderId],
    mutationFn: () => dataProvider.approveQuote(orderId),
    onSuccess: (data) => {
      refresh();
      redirect("edit", "orders", orderId);
    },
  });
  return (
    <Button
      label="Approve"
      onClick={() => {
        mutate();
      }}
      disabled={isPending}
      size="small"
    >
      Approve
    </Button>
  );
};

const CloneQuoteButton = ({ quote }) => {
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const { mutate, isPending } = useMutation({
    mutationKey: ["quote", quote],
    mutationFn: () => dataProvider.cloneQuote(quote),
    onSuccess: (data) => {
      refresh();
      redirect("edit", "quotes", data.data.id);
    },
  });
  return (
    <Button
      label="Clone Quote"
      onClick={() => {
        mutate();
      }}
      disabled={isPending}
      size="small"
    >
      Clone Quote
    </Button>
  );
};

const QuoteShowActions = () => {
  const record = useRecordContext();
  return (
    <TopToolbar>
      <PrevNextButtons
        filter={{ state: "OPEN" }}
        linkType="show"
        sort={{ field: "createdAt", order: "DESC" }}
      />
      <ListButton />
      <EditButton />
      {record && <CustomDownloadButton url={`/quotes/generateQuotePdf/${record.id}`} label="Generate PDF" successfulMessage="Download File" />}
      {record && <ApproveButton orderId={record.id} />}
      {record && <CloneQuoteButton quote={record} />}
    </TopToolbar>
  );
};

export const QuoteShow = () => {
  const record = useRecordContext();
  return (
    <Show title={<QuoteTitle />} actions={<QuoteShowActions />} component="div">
      <SimpleForm>
        <Box>
          <Card>
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="h6" gutterBottom>
                    Quote
                  </Typography>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label="Date">
                        <DateField source="createdAt" showTime />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label="Order Number">
                        <TextField source="orderNumber" />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label="Reference Number">
                        <TextField source="referenceNumber" />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                      <Labeled label="Project Order">
                        <BooleanField source="projectOrder" />
                      </Labeled>
                    </Grid>
                  </Grid>
                  <Spacer />
                  <TextField source="remark" />
                  <Spacer />
                  <Typography variant="h6" gutterBottom>
                    Customer
                  </Typography>
                  <ReferenceField
                    label="Customer"
                    source="customer.id"
                    reference="relations"
                  />
                  <Typography>
                    <TextField source="customer.vatNumber" />
                  </Typography>
                  <Typography>
                    <TextField source="customer.email" />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <Typography variant="h6" gutterBottom>
                    Shipping Address
                  </Typography>
                  <ShippingAddress source="shippingAddress" />
                  <Spacer />
                  <Typography variant="h6" gutterBottom>
                    Billing Address
                  </Typography>
                  <BillingAddress source="billingAddress" />
                </Grid>
              </Grid>
              <Spacer />
              <Typography variant="h6" gutterBottom>
                Items
              </Typography>
              <Box>
                <ArrayField source="items" sort={{ field: "id", order: "ASC" }}>
                  <Datagrid bulkActionButtons={false} rowClick={false}>
                    <CustomProductField source="product.id" />
                    <NumberField
                      source="originalPrice"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                    <NumberField
                      source="price"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                    <NumberField source="qty" />
                    <NumberField
                      source="taxAmount"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                    <PercentNumberField source="taxPercent" />
                    <NumberField
                      source="discountAmount"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                    <PercentNumberField source="discountPercent" />
                    <NumberField
                      source="rowTotalInclTax"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                    <TextArrayField source="productOptions">
                      <SingleFieldList>
                        <ChipField source="id" />
                      </SingleFieldList>
                    </TextArrayField>
                    <NumberField
                      source="coreDeposit"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                  </Datagrid>
                </ArrayField>
              </Box>
              <Spacer />
              <Typography variant="h6" gutterBottom>
                Totals
              </Typography>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography>Subtotal</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NumberField
                      source="subtotal"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Discount</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NumberField
                      source="discountAmount"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Payment Fee</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NumberField
                      source="paymentFeeAmount"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Shipping</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NumberField
                      source="shippingAmount"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Tax</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NumberField
                      source="taxAmount"
                      options={{ style: "currency", currency: "EUR" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>Total</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <NumberField
                      source="grandTotal"
                      options={{ style: "currency", currency: "EUR" }}
                      sx={{ fontWeight: "bold" }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </SimpleForm>
    </Show>
  );
};
