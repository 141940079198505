import * as React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";

export default () => (
  <Card>
    <CardHeader title="Not Found" />
    <CardContent>
      <h1>404: Page not found</h1>
    </CardContent>
  </Card>
);
