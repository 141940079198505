import * as React from "react";
import { useState } from "react";

import { useTranslate, useSidebarState } from "react-admin";

import { DashboardMenuItem, Menu, MenuItemLink } from "react-admin";
import DashboardIcon from "@mui/icons-material/Dashboard";
import TaskIcon from "@mui/icons-material/AssignmentTurnedIn";
import ProductIcon from "@mui/icons-material/Collections";
import RelationIcon from "@mui/icons-material/People";
import QuoteIcon from "@mui/icons-material/RequestQuote";
import OrderIcon from "@mui/icons-material/AttachMoney";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import InventoryIcon from "@mui/icons-material/Inventory";
import ReportIcon from "@mui/icons-material/Assessment";
import ConstructionIcon from "@mui/icons-material/Construction";
import CalculateIcon from "@mui/icons-material/Calculate";
import TollIcon from "@mui/icons-material/Toll";
import SubMenu from "./SubMenu";

export const MyMenu = ({ dense = false }) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Menu>
      {/*{localStorage.getItem("permissions").includes("admin") ? (*/}
      <>
        <SubMenu
          handleToggle={() => handleToggle("menuDashboard")}
          isOpen={state.menuDashboard}
          name="Dashboard"
          icon={<DashboardIcon />}
          dense={dense}
        >
          <DashboardMenuItem primaryText="30 Day History" />
          <MenuItemLink
            to="/productDashboard"
            primaryText="Product/Stock alarms"
            leftIcon={<DashboardIcon />}
          />
        </SubMenu>
        <MenuItemLink
          to="/quotes"
          primaryText="Quotes"
          leftIcon={<QuoteIcon />}
        />
        <MenuItemLink
          to="/orders"
          primaryText="Orders"
          leftIcon={<OrderIcon />}
        />
        <MenuItemLink
          to="/services"
          primaryText="Services"
          leftIcon={<ConstructionIcon />}
        />
      </>
      {/* ) : null}*/}
      <MenuItemLink
        to="/purchases"
        primaryText="Purchases"
        leftIcon={<InventoryIcon />}
      />
      {/*{localStorage.getItem("permissions").includes("admin") ? (*/}
      <>
        <MenuItemLink
          to="/relations"
          primaryText="Relations"
          leftIcon={<RelationIcon />}
        />
        <MenuItemLink
          to={{
            pathname: "/products",
            search: `filter=${JSON.stringify({ active: true })}`,
          }}
          primaryText="Products"
          leftIcon={<ProductIcon />}
        />
        <MenuItemLink
          to="/brands"
          primaryText="Brands"
          leftIcon={<TaskIcon />}
        />
        <MenuItemLink
          to="/productTypes"
          primaryText="Product Types"
          leftIcon={<TaskIcon />}
        />
        <MenuItemLink
          to="/models"
          primaryText="Models"
          leftIcon={<TaskIcon />}
        />
        <SubMenu
          handleToggle={() => handleToggle("menuReports")}
          isOpen={state.menuReports}
          name="Reports"
          icon={<ReportIcon />}
          dense={dense}
        >
          <MenuItemLink
            to="/marginPerBrandPerMonths"
            primaryText="Revenue Report"
            leftIcon={<ReportIcon />}
          />
          <MenuItemLink
            to="/inventorySummaries"
            primaryText="Stock"
            leftIcon={<InventoryIcon />}
          />
          <MenuItemLink
            to="/inventoryValues"
            primaryText="Total Stock Value"
            leftIcon={<InventoryIcon />}
          />
          <MenuItemLink
            to="/currencyRate"
            primaryText="Currency Rate"
            leftIcon={<CurrencyExchangeIcon />}
          />
          <MenuItemLink
            to="/productCores"
            primaryText="Product Cores"
            leftIcon={<TollIcon />}
          />
        </SubMenu>

        <MenuItemLink
          to="/adminConfiguration"
          primaryText="Admin"
          leftIcon={<AdminPanelSettingsIcon />}
        />
        <MenuItemLink
          to="/stockCount"
          primaryText="Stock Count"
          leftIcon={<CalculateIcon />}
        />
      </>
      {/*) : null}*/}
    </Menu>
  );
};
