import { Configuration, SilentRequest, AccountInfo, RedirectRequest } from "@azure/msal-browser";

const getHost = () => {
  if (window && window.location.href) {
    const host = window.location.hostname;
    if (host) {
      return host;
    }
  }
  return null;
};

const getEnvironment = () => {
  if (getHost().includes("localhost")) {
    return "test";
  } else {
    return "prod";
  }
};

const getServerHost = () => {
  if (getEnvironment() === "prod") {
    return "https://stock-app.azurewebsites.net";
  } else {
    return "http://localhost:8080";
  }
};

const getRedirectUri = () => {
  if (getEnvironment() === "prod") {
    return "https://office.pureturbos.be/auth-callback";
  } else {
    return "http://localhost:3000/auth-callback";
  }
};

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    // 'Application (client) ID' of app registration in Azure portal - this value is a GUID
    clientId: "01a85db4-4d66-4636-b088-445cd60a2ab4",
    // Full directory URL, in the form of https://login.microsoftonline.com/<tenant-id>
    authority:
      "https://login.microsoftonline.com/3043ff71-3d43-4b42-af92-1e46e788ea83",
    // Full redirect URL, in form of http://localhost:3000/auth-callback
    redirectUri: `${getRedirectUri()}`,
    // We need to disable this feature because it is already handled by react-admin, and would otherwise conflict
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest : RedirectRequest = {
  scopes: ["User.Read"],
};

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const tokenRequest: SilentRequest = {
  scopes: ["User.Read", "api://f7bb9128-068a-42c2-b52d-53bd8207b0e1/stockapp"],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

export const tokenStockApiRequest: SilentRequest = {
  scopes: ["api://f7bb9128-068a-42c2-b52d-53bd8207b0e1/stockapp"], //, "api://f7bb9128-068a-42c2-b52d-53bd8207b0e1/stockapp"
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

/**
 * Customize this map to match your own roles and permissions
 */
export const rolesPermissionMap: Record<string, string> = {
  "48bb4dd2-6b94-45f3-b3c2-2ea5dc3fef09": "user",
  "8c24aefc-4893-48f4-baee-8498a08c271b": "admin",
};

/**
 * Custom function to map roles to permissions, using the rolesPermissionMap above.
 * Alternatively, you can use the MS Graph API to get more information about the user's roles and groups.
 */
export const getPermissionsFromAccount = async (account: AccountInfo) => {
  const roles = account?.idTokenClaims?.roles ?? [];
  return roles.map((role) => rolesPermissionMap[role]);
};

export default {
  application: "react-gui",
  applicationType: "react",
  environment: getEnvironment(),
  serverHost: getServerHost(),
};
