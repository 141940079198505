import {useRecordContext, Button, Link, CreateButton} from "react-admin";
import { Box, Typography } from "@mui/material";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import * as React from "react";

const AddNewCommentButton = () => {
  const record = useRecordContext();
  return record ? (
      <CreateButton
          label="Add a comment"
          icon={<ChatBubbleIcon />}
          resource="orderComments"
          state={{
              record: {
                  orderId: record.id
              },
          }}
      />
  ) : null;
};

const OrderCommentPanel = () => {
  const record = useRecordContext();
  return (
    <Box sx={{ width: "400px", margin: "1em" }}>
      <Typography variant="h6">Comments</Typography>
      <Typography variant="body2" component={"span"}>
        <Box>
          {record &&
            record.comments.map((comment) => (
              <Typography key={comment.id} component={"span"}>
                <b>{comment.createdAt}</b>
                <br />
                <small>{comment.description}</small>
                <br />
              </Typography>
            ))}
        </Box>
      </Typography>
      <Typography>
        <AddNewCommentButton />
      </Typography>
    </Box>
  );
};

export default OrderCommentPanel;
